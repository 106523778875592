/**
 * Simple implementation of LRU + SWR cache approaches to enable fast cache retrieval at the same time allowing refresh in the background
 */

import { AxiosError } from "axios";
import { LRUCache } from "lru-cache";
import { serializeError } from "serialize-error";

const lruCache = new LRUCache<string, CacheFormat<any>>({
  max: 1000,
  // These two options are reduntant since we cache forever, but if we decide to change it
  // they will be helpful
  allowStale: true,
  updateAgeOnGet: true,
});

type CacheFormat<T> = {
  cacheAge: number;
  data: T;
};

const IN_PROGRESS: Record<string, Promise<any> | null> = {};

export type GetSwrProps<T> = {
  key: object | string;
  timeout?: number;
  fetch: () => Promise<T>;
};

/**
 * Returns a stale version of item while it refreshes cached entries in the background
 */
export async function getStaleWhileRefresh<T>({
  key,
  fetch,
  timeout = 1000 * 10,
}: GetSwrProps<T>): Promise<T> {
  const cacheKey = JSON.stringify(key, null, 0);
  const cachedItem = lruCache.get(cacheKey);

  // Prevent multiple requests
  if (!cachedItem && IN_PROGRESS[cacheKey]) {
    return IN_PROGRESS[cacheKey];
  }

  if (cachedItem) {
    // We hit a cached item, refresh it if beyond cache age
    if (Date.now() - (cachedItem.cacheAge + timeout) > 0) {
      refreshCache();
    }

    return cachedItem.data;
  }

  refreshCache();

  function fetchAndSet() {
    return fetch()
      .then((data) => {
        lruCache.set(cacheKey, {
          cacheAge: Date.now(),
          data,
        });

        IN_PROGRESS[cacheKey] = null;
        delete IN_PROGRESS[cacheKey];
        return data;
      })
      .catch((err: Error | AxiosError) => {
        IN_PROGRESS[cacheKey] = null;
        delete IN_PROGRESS[cacheKey];

        let errorJson;

        if (isAxiorError(err)) {
          errorJson = serializeError({
            response: err.response?.data,
            code: err.code,
            message: err.message,
            stack: err.stack,
          });
        } else {
          errorJson = serializeError(err);
        }

        /* eslint-disable no-console */
        console.error(
          JSON.stringify({
            message: `[swr-cache] Error: failed to update cache for ${cacheKey}`,
            error: errorJson,
          }),
        );

        // Throw an error only if we don't have anything cached
        if (!cachedItem) {
          throw new Error("[swr-cache] Failed to fetch.");
        }
      });
  }

  function refreshCache() {
    // If we don't have a fetch already in progress then do one
    if (!IN_PROGRESS[cacheKey]) {
      IN_PROGRESS[cacheKey] = fetchAndSet();
    }
  }

  return IN_PROGRESS[cacheKey];
}

function isAxiorError(err: any): err is AxiosError {
  return Boolean(err.isAxiosError);
}
