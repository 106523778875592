export const getStickyPostsScheme = `
  query GET_STICKY_POSTS {
    posts(where: {onlySticky: true}, first: 5) {
      nodes {
        id
        title
        slug
        featuredImage {
          node {
            altText
            mediaItemUrl
            mediaDetails {
              height
              width
              sizes {
                height
                sourceUrl
                width
              }
            }
          }
        }
        tags {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`;

/**
 * 4 posts for Desktop, 5 for mobile
 */
export const getCategoryTabsScheme = `
  query GET_CATEGORY_TABS {
    categories {
      edges {
        node {
          categoryPageFields {
            categoryColor
          }
          name
          slug
          posts(first: 5) {
            nodes {
              id
              title
              slug
              featuredImage {
                node {
                  altText
                  mediaItemUrl
                  mediaDetails {
                    height
                    width
                    sizes {
                      height
                      sourceUrl
                      width
                    }
                  }
                }
              }
              tags {
                nodes {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * Use getRecentPostsScheme from Schemes file
 */
