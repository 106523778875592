export const getStickyPostsForCategoryScheme = (id: string) => `
  query GET_STICKY_POSTS_FOR_CATEGORY {
    posts(where: {onlySticky: true, categoryName: "${id}"}, first: 5) {
      nodes {
        id
        title
        slug
        featuredImage {
          node {
            altText
            mediaItemUrl
            mediaDetails {
              height
              width
              sizes {
                height
                sourceUrl
                width
              }
            }
          }
        }
        tags {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`;

export const getColorForCategoryScheme = (id: string) => `
  query GET_COLOR_FOR_CATEGORY {
    category(id: "${id}", idType: SLUG) {
      categoryPageFields {
        categoryColor
      }
    }
  }
`;

export const getCategoryScheme = `
  query GET_CATEGORY($id: ID!) {
    category(id: $id, idType: SLUG) {
      name
      description
      slug
      count
      categoryPageFields {
        categoryColor
      }
      seo {
        title
        metaDesc
        opengraphImage {
          mediaItemUrl
        }
      }
    }
  }
`;
