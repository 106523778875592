import {
  Category,
  CategoryToAncestorsCategoryConnection,
  Maybe,
  Page,
  Post,
  RootQueryToPostConnection,
  Tag,
  TagConnection,
} from "interfaces/wordpress";
import { Categories } from "lib/constants";
import { getStaleWhileRefresh } from "./memory-cache";
import { valueExists } from "interfaces";
import { axiosWithRetry } from "lib/axios-with-retry";
import {
  getFeaturedPostsForCategoryScheme as getFeaturedPostsForCategorySchemeHandler,
  getIndexPageQueryScheme,
  getInstagramFeedScheme,
  getPostForArticlePageScheme as getPostForArticlePageSchemeHandler,
  getPostsForHomeScheme,
  getPostsForSitemapScheme,
  getRecentCategoryPostsScheme,
  getRecentPostsScheme,
  getRelatedPostsForCategoryScheme,
} from "lib/schemes";
import {
  getCategoryTabsScheme,
  getStickyPostsScheme,
} from "lib/schemes/main/main";

export const POST_COUNT = 12;
export const setPostCount = (total: number) => {
  // Remove first Batch - 1 from total number - as we use total number as pagination counter, where 1 is set by default
  return parseInt(`${total / POST_COUNT}`, 10) - 1;
};

import {
  getCategoryScheme,
  getColorForCategoryScheme as getColorForCategorySchemeHandler,
  getStickyPostsForCategoryScheme as getStickyPostsForCategorySchemeHandler,
} from "lib/schemes/category/category";

import {
  getTagScheme,
  checkTagExistsScheme as checkTagExistsSchemeHandler,
  getRecentPostsForTagScheme,
  getStickyPostsForTagScheme as getStickyPostsForTagSchemeHandler,
} from "lib/schemes/tag/tag";
import { clearHtmlTags } from "lib/decode";

export const FEATURED_POST_TAG = "main-page-featured-post";
// local posts are not shown on main page
export const LOCAL_POST_TAG = "local-post";
export const SPLASH_POST_TAG = "main-page-hero-block-post";

function setToLowerCase(value: string) {
  return value.toLocaleLowerCase();
}

function setKebabCase(value: string) {
  return value.replace(" ", "-");
}

function setTag(value: string) {
  return setKebabCase(setToLowerCase(value));
}

type Headers = {
  "Content-Type": string;
  Authorization?: string;
  "X-Query-Name": string;
};

function escapeRegExp(string: string) {
  return string.replaceAll("/", "\\/");
}

function transformResponse(responseString: string) {
  if (!process.env.INPUT_STORAGE_PATH) {
    console.log(`env var INPUT_STORAGE_PATH is not defined!`);
    return responseString;
  }

  if (!process.env.OUTPUT_CDN_URL) {
    console.log(`env var OUTPUT_CDN_URL is not defined!`);
    return responseString;
  }

  return (
    responseString
      //   .replace(
      //     /http:\\\/\\\/bumble-buzz-ceph\.mlan:7480\\\//g,
      //     "https://bumble.com/the-buzz/cdn-image/"
      //   )
      //   .replace(/src=\\"\\" data-src=\\"/g, 'loading=\\"lazy\\" src=\\"');
      .replaceAll(
        escapeRegExp(process.env.INPUT_STORAGE_PATH || ""),
        process.env.OUTPUT_CDN_URL || "",
      )
  );
}

// Removes unnecessary spaces from query to make it easier to read in the logs
function trimQuery(query: string) {
  return query.replace(/(\s|\n)+/g, " ");
}

async function fetchCms(
  apiUrl: string,
  authToken: string | undefined,
  query: string,
  { variables } = {} as { variables: Record<string, any> },
) {
  // Try to get the query so we can pass to access logs
  const queryName = query.match(/query(\s+)(\w+)/)?.[2] || "";

  const headers: Headers = {
    "Content-Type": "application/json",
    "X-Query-Name": queryName,
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  const response = await axiosWithRetry.post(
    apiUrl,
    JSON.stringify({
      query: trimQuery(query),
      variables,
    }),
    {
      headers,
      transformResponse,
    },
  );

  const text = await response.data;

  const json = JSON.parse(text);

  if (json && json.errors) {
    console.error(JSON.stringify(json.errors, null, 4));
    throw new Error("Failed to fetch API");
  }

  return json.data;
}

async function fetchTheBuzzWordpress(
  query: string,
  vars = {} as { variables: Record<string, any> },
) {
  return fetchCms(
    process.env.THE_BUZZ_API_URL as string,
    process.env.THE_BUZZ_AUTH_REFRESH_TOKEN as string,
    query,
    vars,
  );
}

type InstagramFeedApiResponse = {
  data: {
    // @Todo: after proper integration put proper types
    // instagramFeed: InstagramFeedItem[] | [];
    instagramFeed?: string;
  };
};

function getUnEscapedUrl(url: string) {
  return url.replace(/&amp;/g, "&");
}

function getIntagramFeedFromMainSite(feedSource: string) {
  const feed = JSON.parse(feedSource);
  const instagramFeed = feed.articles.map((item: any) => {
    const { post_url, thumb_url } = item;

    return {
      url: getUnEscapedUrl(post_url),
      thumbnail: getUnEscapedUrl(thumb_url),
      caption: "",
    };
  });

  return instagramFeed;
}

export async function getInstagramFeed(
  url?: string,
): Promise<InstagramFeedApiResponse> {
  return getStaleWhileRefresh({
    key: { id: `getInstagramFeed${url}` },
    // 1 hour
    timeout: 1000 * 60 * 60,
    fetch: async () => {
      const variables = {
        variables: {
          id: url,
        },
      };
      let istagramFeed = [];

      try {
        const data: InstagramFeedApiResponse["data"] =
          await fetchTheBuzzWordpress(getInstagramFeedScheme, variables);

        istagramFeed =
          typeof data?.instagramFeed === "string"
            ? getIntagramFeedFromMainSite(data?.instagramFeed)
            : [];
      } catch (error) {
        console.error(error);
      }

      return istagramFeed;
    },
  });
}

export async function getRecentPosts(
  count = 3,
  after = null as string | null,
  categoryName = null as string | null,
  tag = null as string | null,
): Promise<{
  posts: Post[];
  hasNextPage: boolean;
  endCursor: string;
  total: number;
}> {
  // @ts-ignore
  return getStaleWhileRefresh({
    key: { id: "getRecentPosts", count, after },
    fetch: async () => {
      const variables = {
        variables: {
          first: count,
          after,
          categoryName,
          tag,
          tagSlugNotIn: [LOCAL_POST_TAG, SPLASH_POST_TAG],
        },
      };
      const data: { posts: RootQueryToPostConnection } =
        await fetchTheBuzzWordpress(getRecentPostsScheme, variables);
      return {
        posts: (data.posts.edges || [])
          .filter(valueExists)
          .map((edge) => edge.node)
          .filter(valueExists),
        endCursor: data.posts.pageInfo?.endCursor || "",
        hasNextPage: Boolean(data.posts.pageInfo?.hasNextPage),
        total: data.posts.pageInfo?.total || 1,
      };
    },
  });
}

type getRecentCategoryPostsProps = {
  categoryName: Categories | null;
  count: number;
  after?: string | null;
};
type getRecentCategoryPostsReturnType = Promise<{
  name: string;
  posts: Post[];
  hasNextPage: boolean;
  endCursor: string;
  total: number;
}>;

// currently taxQuery is not applying here - need to figure out, why it is wokring only for top level
export async function getRecentCategoryPosts({
  categoryName,
  count = 3,
  after = null,
}: getRecentCategoryPostsProps): getRecentCategoryPostsReturnType {
  // @ts-ignore
  return getStaleWhileRefresh({
    key: { id: "getRecentCategoryPosts", categoryName, count, after },
    fetch: async () => {
      const variables = {
        variables: {
          categoryName,
          first: count,
          after,
          tagSlugNotIn: [FEATURED_POST_TAG, LOCAL_POST_TAG, SPLASH_POST_TAG],
        },
      };
      const data: {
        categories: CategoryToAncestorsCategoryConnection;
      } = await fetchTheBuzzWordpress(getRecentCategoryPostsScheme, variables);

      const posts = data.categories.edges?.[0]?.node?.posts;

      return {
        name: data.categories.edges?.[0]?.node?.name || null,
        posts:
          posts?.edges
            ?.filter(valueExists)
            .map((edge) => edge.node)
            .filter(valueExists) || [],
        endCursor: posts?.pageInfo?.endCursor || "",
        hasNextPage: Boolean(posts?.pageInfo?.hasNextPage),
        total: posts?.pageInfo?.total || 1,
      };
    },
  });
}

type getPostsForSitemapReturnType = Promise<{
  posts: RootQueryToPostConnection;
}>;

export async function getPostsForSitemap(): getPostsForSitemapReturnType {
  return getStaleWhileRefresh({
    key: "sitemap",
    fetch: () => fetchTheBuzzWordpress(getPostsForSitemapScheme),
  });
}

export type HomePosts = {
  splashPost: RootQueryToPostConnection;
  featuredPosts: RootQueryToPostConnection;
  category_Love: CategoryToAncestorsCategoryConnection;
  category_Friendship: CategoryToAncestorsCategoryConnection;
  category_Career: CategoryToAncestorsCategoryConnection;
  category_HowTo: CategoryToAncestorsCategoryConnection;
  category_Wellness: CategoryToAncestorsCategoryConnection;
  category_Events: CategoryToAncestorsCategoryConnection;
};

export async function getPostsForHome(): Promise<HomePosts> {
  return getStaleWhileRefresh({
    key: { id: "getPostsForHome" },
    fetch: () => {
      return fetchTheBuzzWordpress(getPostsForHomeScheme);
    },
  });
}

export async function getSettingsForHomePage(): Promise<string | null> {
  try {
    return getStaleWhileRefresh({
      key: { id: "getSettingsForHomePage" },
      fetch: () => {
        return fetchTheBuzzWordpress(getPostsForHomeScheme);
      },
    });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getFeaturedPostsForCategory(
  categoryName = null as Categories | null,
) {
  const categoryHeroBlockPostTag = categoryName
    ? `${setTag(categoryName)}-hero-block-post`
    : ``;
  const categoryFeaturedPostTag = categoryName
    ? `${setTag(categoryName)}-featured-post`
    : ``;

  return getStaleWhileRefresh({
    key: { id: "getFeaturedPostsForCategory", categoryName },
    fetch: async () => {
      const variables = {
        variables: {
          categoryName,
        },
      };
      const getFeaturedPostsForCategoryScheme =
        getFeaturedPostsForCategorySchemeHandler(
          categoryHeroBlockPostTag,
          categoryFeaturedPostTag,
        );
      const data: {
        splashPost: CategoryToAncestorsCategoryConnection;
        featuredPosts: CategoryToAncestorsCategoryConnection;
      } = await fetchTheBuzzWordpress(
        getFeaturedPostsForCategoryScheme,
        variables,
      );

      return {
        subTitle:
          data?.splashPost?.edges?.[0]?.node?.categoryPageFields?.pageTitle ||
          null,
        splashPost:
          data?.splashPost?.edges?.[0]?.node?.posts?.edges
            ?.map((edge) => edge?.node)
            .filter(valueExists) || [],
        featuredPosts:
          data?.featuredPosts?.edges?.[0]?.node?.posts?.edges
            ?.map((edge) => edge?.node)
            .filter(valueExists) || [],
      };
    },
  });
}

export async function getRelatedPostsForCategory(
  categoryName: string,
  postId: number,
) {
  return getStaleWhileRefresh({
    key: { id: "getRelatedPostsForCategory", categoryName, postId },
    fetch: async () => {
      const variables = {
        variables: {
          categoryName: [categoryName],
          postId: [postId],
        },
      };
      const data: {
        posts: CategoryToAncestorsCategoryConnection;
      } = await fetchTheBuzzWordpress(
        getRelatedPostsForCategoryScheme,
        variables,
      );

      return (
        data?.posts?.edges?.[0]?.node?.posts?.edges
          ?.map((edge) => edge?.node)
          .filter(valueExists) || []
      );
    },
  });
}

export async function getPostForArticlePage(slug: string) {
  return getStaleWhileRefresh({
    key: { id: "getPostForArticlePage", slug },
    fetch: async () => {
      const variables = {
        variables: {
          id: slug,
        },
      };
      // This allows supporting preview mode by loading urls like /the-buzz/10003
      const idType = isNaN(slug as unknown as number) ? "SLUG" : "DATABASE_ID";
      const getPostForArticlePageScheme =
        getPostForArticlePageSchemeHandler(idType);

      const { post }: { post: Post } = await fetchTheBuzzWordpress(
        getPostForArticlePageScheme,
        variables,
      );

      return post;
    },
  });
}

export async function getIndexPageSettings() {
  return getStaleWhileRefresh({
    key: { id: `getIndexPageSettings` },
    fetch: async () => {
      const data: {
        nodeByUri: Page;
      } = await fetchTheBuzzWordpress(getIndexPageQueryScheme);

      return { subTitle: data?.nodeByUri?.indexPageFields?.pageTitle || "" };
    },
  });
}

export async function getStickyPosts() {
  return getStaleWhileRefresh({
    key: { id: `getStickyPosts` },
    fetch: async () => {
      const data: {
        /**
         * @TODO: later change type as there are nodes
         */
        posts: RootQueryToPostConnection;
      } = await fetchTheBuzzWordpress(getStickyPostsScheme);

      return { stickyPosts: data?.posts || [] };
    },
  });
}

export async function getCategoryTabs() {
  return getStaleWhileRefresh({
    key: { id: `getCategoryTabs` },
    fetch: async () => {
      const data: {
        categories: CategoryToAncestorsCategoryConnection;
      } = await fetchTheBuzzWordpress(getCategoryTabsScheme);

      return { categoryTabs: data?.categories || [] };
    },
  });
}

export async function getColorForCategory(id: string) {
  return getStaleWhileRefresh({
    key: { id: `getColorForCategory` },
    fetch: async () => {
      const getColorForCategoryScheme = getColorForCategorySchemeHandler(id);
      const data: {
        category: Category;
      } = await fetchTheBuzzWordpress(getColorForCategoryScheme);

      return { color: data.category.categoryPageFields?.categoryColor || [] };
    },
  });
}

export type GetCategoryReturnType = {
  title?: Maybe<string> | undefined;
  name?: Maybe<string> | undefined;
  slug?: Maybe<string> | undefined;
  description?: Maybe<string> | undefined;
  count?: Maybe<number> | undefined;
  color?: Maybe<string> | undefined;
  imageSrc?: Maybe<string> | undefined;
};

export async function getCategory(id: string): Promise<GetCategoryReturnType> {
  return getStaleWhileRefresh({
    key: { id: `getCategoryForId${id}` },
    fetch: async () => {
      const variables = {
        variables: {
          id,
        },
      };
      const data: {
        category: Category;
      } = await fetchTheBuzzWordpress(getCategoryScheme, variables);

      let description = clearHtmlTags(data.category.description || "") || null;

      if (
        data.category.seo?.metaDesc &&
        data.category.seo?.metaDesc?.length > 0
      ) {
        description = data.category.seo?.metaDesc;
      }

      return {
        title: data.category.seo?.title,
        name: data.category.name,
        slug: data.category.slug,
        description,
        count: data.category.count,
        color: data.category.categoryPageFields?.categoryColor,
        imageSrc: data.category.seo?.opengraphImage?.mediaItemUrl || null,
      };
    },
  });
}

export async function getStickyPostsForCategory(id: string) {
  return getStaleWhileRefresh({
    key: { id: `getStickyPostsForCategory` },
    fetch: async () => {
      const getStickyPostsForCategoryScheme =
        getStickyPostsForCategorySchemeHandler(id);
      const data: {
        posts: Post[];
      } = await fetchTheBuzzWordpress(getStickyPostsForCategoryScheme);

      return { stickyPosts: data.posts || [] };
    },
  });
}

export async function checkTagExists(id: string) {
  return getStaleWhileRefresh({
    key: { id: `checkTagExistsFor${id}` },
    fetch: async () => {
      const checkTagExistsScheme = checkTagExistsSchemeHandler(id);
      const data: {
        tag: Tag;
      } = await fetchTheBuzzWordpress(checkTagExistsScheme);

      return { tag: data.tag || null };
    },
  });
}

export async function getStickyPostsForTag(tagName: string) {
  return getStaleWhileRefresh({
    key: { id: `getStickyPostsForTag${tagName}` },
    fetch: async () => {
      const getStickyPostsForTagScheme =
        getStickyPostsForTagSchemeHandler(tagName);
      const data: {
        posts: Post[];
      } = await fetchTheBuzzWordpress(getStickyPostsForTagScheme);

      return { stickyPosts: data.posts || [] };
    },
  });
}

export async function getRecentPostsForTag(tagName: string, count = 12) {
  return getStaleWhileRefresh({
    key: { id: `getRecentPostsForTag${tagName}` },
    fetch: async () => {
      const variables = {
        variables: {
          first: count,
          tagName,
        },
      };
      const data: {
        tags: TagConnection;
      } = await fetchTheBuzzWordpress(getRecentPostsForTagScheme, variables);

      return {
        name: data.tags.edges?.[0]?.node?.name || null,
        posts: (data.tags.edges[0].node.posts?.edges || [])
          .filter(valueExists)
          .map((edge) => edge.node)
          .filter(valueExists),
        endCursor: data.tags.edges[0].node.posts?.pageInfo?.endCursor || "",
        hasNextPage: Boolean(
          data.tags.edges[0].node.posts?.pageInfo?.hasNextPage,
        ),
        total: data.tags.edges[0].node.posts?.pageInfo?.total || 1,
      };
    },
  });
}

export type GetTagReturnType = {
  title?: Maybe<string> | undefined;
  name?: Maybe<string> | undefined;
  slug?: Maybe<string> | undefined;
  description?: Maybe<string> | undefined;
  count?: Maybe<number> | undefined;
  imageSrc?: Maybe<string> | undefined;
};

export async function getTag(id: string): Promise<GetTagReturnType> {
  return getStaleWhileRefresh({
    key: { id: `getTagForId${id}` },
    fetch: async () => {
      const variables = {
        variables: {
          id,
        },
      };
      const data: {
        tag: Tag;
      } = await fetchTheBuzzWordpress(getTagScheme, variables);

      let description = clearHtmlTags(data.tag.description || "") || null;

      if (data.tag.seo?.metaDesc && data.tag.seo?.metaDesc?.length > 0) {
        description = data.tag.seo?.metaDesc;
      }

      return {
        title: data.tag.seo?.title,
        name: data.tag.name,
        slug: data.tag.slug,
        description,
        count: data.tag.count,
        imageSrc: data.tag.seo?.opengraphImage?.mediaItemUrl || null,
      };
    },
  });
}
